<template>
  <div class="my-profile">
    <div class="email-leftbar card">
      <div class="mail-list mt-4">
        <b-tabs vertical tag="a" v-model="tabIndex">
          <b-tab v-for="tab in tabs" :key="tab.text">
            <template #title>
              <i :class="`mdi mdi-${tab.icon} mr-2`"></i>
              <span>{{ $t(tab.text) }}</span>
              <span class="ml-1 float-right" v-if="tab.badge"
                >({{ $t("badge") }})</span
              >
            </template>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <!-- Right panel -->
    <ebp-card class="email-rightbar mb-3 card">
      <h5 class="mb-4">{{ $t(tabs[tabIndex].text) }}</h5>
      <component :is="tabs[tabIndex].text" />
    </ebp-card>
  </div>
</template>

<script>
const PaymentPreferences = () =>
  import(
    /* webpackChunkName: "payment-preferences" */ "./my-profile/PaymentPreferences"
  );
const Profile = () =>
  import(/* webpackChunkName: "profile" */ "./my-profile/Profile");

import { kebab } from "case";

export default {
  name: "my-profile",
  components: {
    PaymentPreferences,
    Profile
  },
  data() {
    return {
      error: null,
      tabs: [
        {
          text: "profile",
          icon: "account-circle-outline"
        },
        {
          icon: "credit-card-outline",
          text: "payment-preferences"
        }
      ],
      tabIndex: 0
    };
  },
  mounted() {
    if (!this.$route.query.tab) {
      this.handleTabQuery();
    } else {
      this.activateTab(this.$route.query.tab);
    }
  },
  methods: {
    kebab,
    handleTabQuery() {
      const tab = this.tabs[this.tabIndex];
      if (tab && tab.text !== this.$route.query.tab) {
        this.$router.push({ query: { tab: tab.text } });
      }
    },
    activateTab(tab) {
      if (this.tabs.map(t => t.text).includes(tab)) {
        setTimeout(() => {
          this.tabIndex = this.tabs.map(t => t.text).indexOf(tab);
        }, 100);
      }
    }
  },
  watch: {
    tabIndex() {
      this.handleTabQuery();
    }
  }
};
</script>

<style lang="scss">
.my-profile {
  width: 100% !important;
}
</style>
